import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";


// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
    "chnagePassword",
    async (details) => {
      const data = await AdminAPI.patch(`/change-password`, details);
      console.log(data);
      return data;
    }
  );


  // ===== Get Admin Profile ==========


export const  getAdminprofile = createAsyncThunk(
  "getAdminprofile",
  async()=> {
     const {data} = await AdminAPI.get(`/profile`)
     return data;
  }
);


// ====== Edit admin profile ==========

export const Editadminprofile = createAsyncThunk(
  "Editadminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/profile`, details);
    console.log(data);
    return data;
  }
);

// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/users?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }


    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


  // ======== Delete User account api =============

  export const DeleteUser = createAsyncThunk(
    "DeleteUser",
    async(details) => {
        const data = await AdminAPI.delete(`/user/${details?.id}`);
       return data;
    } 
  );

  // =======  Dowanload Users List ============

  export const DowanloadUserxlsv = createAsyncThunk(
    "DowanloadUserxlsv",
    async () => {
      const data = await AdminAPI.get(`/users`);
      console.log(data);
      return data;
    }
  );


  // ===== Forget Password =======

export const forgetPass = createAsyncThunk(
  "forgetPass",
  async(details) => {
      const data = await AdminAPI.post(`/forgot-password`,details);
     return data;
  } 
);


// ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
  "ResetPass",
  async(details) => {
      const data = await AdminAPI.post(`/set-password`,details);
     return data;
  } 
);



// ===== Get user Profile =====

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/user/${details?.id}`);
    console.log(data);
    return data;
  }
);


// ================ get user activity ===================

// export const  getActivity = createAsyncThunk(
//   "getActivity",
//   async(details)=> {
//      const {data} = await AdminAPI.get(`/getActivities?user_id=${details?.id}`)
//      return data;
//   }
// );



  // ======== suspend User account api =============

  export const suspenduser = createAsyncThunk(
    "suspenduser",
    async(details) => {
        const data = await AdminAPI.patch(`/user/${details?.id}`, details);
       return data;
    } 
  );


    // ======== Delete User account api =============

    export const DeleteUserAccount = createAsyncThunk(
      "DeleteUserAccount",
      async(details) => {
          const data = await AdminAPI.delete(`/user/${details?.id}`,details);
         return data;
      } 
    );



     // ======== magage User account api =============

  export const maangeuseraction = createAsyncThunk(
    "maangeuseraction",
    async(details) => {
        const data = await AdminAPI.patch(`/user/${details?.id}`, details);
       return data;
    } 
  );

  // ============== Add FAQ ===============

  export const addfaq = createAsyncThunk(
    "AddFaq",
    async(details) => {
        const data = await AdminAPI.post(`/faq`, details);
       return data;
    } 
  );


  // ==================  List FAQ =================

  export const FAQList = createAsyncThunk(
    "FAQList",
    async (details) => {
      let url = `/faq?pagination=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&search=${details.search}`;
      }
  
  
      const data = await AdminAPI.get(url);
      console.log(data);
      return data;
    }
  );

   // ============== dowanload FAQ ===============

   export const dowanloadfaq = createAsyncThunk(
    "dowanloadfaq",
    async() => {
        const data = await AdminAPI.get(`/faq`);
       return data;
    } 
  );


  // ============ Edit faq ================

  export const editFaq = createAsyncThunk(
    "EditFaq",
    async(details) => {
        const data = await AdminAPI.put(`/faq/${details?.id}`, details);
       return data;
    } 
  );


  // ==========  delete faq =================

     export const deletefaq = createAsyncThunk(
      "deletefaq",
      async(details) => {
          const data = await AdminAPI.delete(`/faq/${details?.id}`,details);
         return data;
      } 
    );
  

      // ==========  get faq details =================

      export const getfaqdetails = createAsyncThunk(
        "gatfaqdetails",
        async(details) => {
            const data = await AdminAPI.get(`/faq/${details?.faqid}`);
           return data;
        } 
      );
  