import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  DeleteUser,
  DowanloadUserxlsv,
  getAdminprofile,
  maangeuseraction,
  suspenduser,
  usermanagement,
} from "../Redux/Actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import UserIcon from "../Assets/Images/table-user.svg";
import TrainerIcon from "../Assets/Images/crown.svg";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import PaginationComponet from "../Components/Pagination";
import { OverlayTrigger, Popover } from "react-bootstrap";
import SearchBar from "../Common/SearchBar";
import { CommonLogout } from "../Common/CommonLogout";

export default function User() {

  const { alldata, loading, error, param } = useSelector(
    (state) => state.usermgt
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const [admindetails, setAdminDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      usermanagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    dispatch(getAdminprofile())
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.status === 200) {
          setAdminDetails(response?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(usermanagement(searchData));
  }, [page, limit, searchValue, flag, dispatch]);

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadUserxlsv());
      console.log(response);

      const allData = response?.payload?.data?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const ActiveOrDeactivate = (userid, status) => {
    dispatch(maangeuseraction({ id: userid, status: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };

  const popover = (id) => {
    return (
      <Popover id="popover-basic" style={{ cursor: "pointer" }}>
        <Popover.Body>
          <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
          <p onClick={() => ActiveOrDeactivate(id, 2)}>In-Active</p>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Layout>
      {/* {loading ?
     <div>Loading...</div> 
     : */}
      <>
        <Container fluid>
          <div className="dashboard-items mt-3">
            <div className="right-top-bar">
              <div className="left-side-bar">
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19 19L13 13"
                  stroke="#2F2B3D"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="7"
                  stroke="#2F2B3D"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg> */}
                {/* <input type="tetx" placeholder="Search " /> */}
              </div>
              <div className="right-side-bar">
                <div className="notify-icon">
                  <div className="notification-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="user-img-top">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {admindetails && admindetails?.image ? (
                        <img
                          src={`${url}public/${admindetails?.image}`}
                          alt="Profile"
                        />
                      ) : (
                        Logo
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item className="">
                        <div className="drop-pro-view">
                          {admindetails && admindetails?.image ? (
                            <img
                              src={`${url}public/${admindetails?.image}`}
                              alt="Profile"
                            />
                          ) : (
                            Logo
                          )}
                          <div className="admin-name">
                            <h3>{admindetails?.name}</h3>
                            <p>Admin</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <div className="my-profile">
                        <Dropdown.Item href="my-profile">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <ellipse
                              cx="10.9999"
                              cy="6.41667"
                              rx="3.66667"
                              ry="3.66667"
                              stroke="#2F2B3D"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                              stroke="#2F2B3D"
                              stroke-opacity="0.9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>{" "}
                          My Profile
                        </Dropdown.Item>
                      </div>

                      {/* <div className="my-profile">
                      <Dropdown.Item href="#/action-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.46458 3.95725C9.85508 2.34758 12.1449 2.34758 12.5354 3.95725C12.6543 4.44772 13.0002 4.85194 13.4664 5.0451C13.9327 5.23826 14.4631 5.19713 14.894 4.93442C16.3084 4.07275 17.9282 5.69158 17.0665 7.10692C16.8042 7.53762 16.7631 8.06766 16.9561 8.5336C17.149 8.99954 17.5527 9.34542 18.0428 9.46458C19.6524 9.85508 19.6524 12.1449 18.0428 12.5354C17.5523 12.6543 17.1481 13.0002 16.9549 13.4664C16.7617 13.9327 16.8029 14.4631 17.0656 14.894C17.9272 16.3084 16.3084 17.9282 14.8931 17.0665C14.4624 16.8042 13.9323 16.7631 13.4664 16.9561C13.0005 17.149 12.6546 17.5527 12.5354 18.0428C12.1449 19.6524 9.85508 19.6524 9.46458 18.0428C9.34574 17.5523 8.9998 17.1481 8.53357 16.9549C8.06734 16.7617 7.53689 16.8029 7.106 17.0656C5.69158 17.9272 4.07183 16.3084 4.9335 14.8931C5.19584 14.4624 5.23687 13.9323 5.04393 13.4664C4.851 13.0005 4.44727 12.6546 3.95725 12.5354C2.34758 12.1449 2.34758 9.85508 3.95725 9.46458C4.44772 9.34574 4.85194 8.9998 5.0451 8.53357C5.23826 8.06734 5.19713 7.53689 4.93442 7.106C4.07275 5.69158 5.69158 4.07183 7.10692 4.9335C8.02358 5.49083 9.21158 4.99767 9.46458 3.95725Z"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <circle
                            cx="11"
                            cy="11"
                            r="2.75"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        Settings
                      </Dropdown.Item>
                    </div> */}
                      <Dropdown.Divider />
                      {/* <div className="my-profile">
                      <Dropdown.Item href="#/action-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            d="M7.33325 7.33337C7.33325 5.81459 8.76967 4.58337 10.5416 4.58337H11.4583C13.2302 4.58337 14.6666 5.81459 14.6666 7.33337C14.7359 8.55599 13.9885 9.67715 12.8333 10.0834C11.678 10.625 10.9306 12.1199 10.9999 13.75"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.0001 17.4166V17.4258"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        FAQ
                      </Dropdown.Item>
                    </div> */}

                      {/* <Dropdown.Item
                        href="/"
                        className="logout-btn"
                      >
                        Logout
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                        >
                          <path
                            d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                            fill="white"
                          />
                        </svg>
                      </Dropdown.Item> */}

                      <CommonLogout/>

                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Container fluid>
          <div className="dashboard-items">
            <Row>
              <Col xxl={12} xl={12} lg={12}>
                <div className="dashbox-inner-wrap">
                  <Row>
                    <Col xxl={3} xl={3} lg={3}>
                      <div className="dash-inner-boxes">
                        <div className="session">
                          <div className="left-session">
                            <p>Total Users</p>
                            <h2>
                              {alldata?.count}
                              {/* <span>(+29%)</span> */}
                            </h2>
                            {/* <span className="total-users">Total Users</span> */}
                          </div>
                          <div className="user-icon-se">
                            <img
                              src={
                                require("../Assets/Images/session-user.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3}>
                      <div className="dash-inner-boxes">
                        <div className="session">
                          <div className="left-session">
                            <p>Total Trainer</p>
                            <h2>
                              {alldata?.total_trainers || "N/A"}
                              {/* <span> (+18%) </span> */}
                            </h2>
                            {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                          </div>
                          <div className="user-icon-se">
                            <img
                              src={
                                require("../Assets/Images/padi-user.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3}>
                      <div className="dash-inner-boxes">
                        <div className="session">
                          <div className="left-session">
                            <p>Active Users</p>
                            <h2>0</h2>
                            {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                          </div>
                          <div className="user-icon-se">
                            <img
                              src={
                                require("../Assets/Images/active-user.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3}>
                      <div className="dash-inner-boxes">
                        <div className="session">
                          <div className="left-session">
                            <p>Inactive Users</p>
                            <h2>0{/* <span>(+42%)</span> */}</h2>
                            {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                          </div>
                          <div className="user-icon-se">
                            <img
                              src={
                                require("../Assets/Images/pending-user.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Container fluid>
          <div className="filters">
            <div className="inner-filter-field">
              <div className="user-search">
                <div className="drop-down">
                  <div className="select-box">
                    <Form.Select
                      aria-label="Default select example"
                      name="limit"
                      onChange={(e) => setlimit(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="search-tab">
                  <div className="input-search">
                    <Form>
                      <Form.Group controlId="formBasicPassword">
                        <SearchBar setSearchValue={setSearchValue} />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="export-box">
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/upload.svg").default}
                          onClick={fetchDataAndExportToExcel}
                        />
                      </span>
                      Export
                    </p>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>
                        {/* <div className="user-checkbox"> */}
                        {/* <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div> */}
                        User
                        {/* </div> */}
                      </th>
                      <th>Role</th>
                      <th>Contact</th>
                      <th>Age </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alldata?.data?.map((res, index) => {
                      return (
                        <tr>
                          <td>
                            <div className="first-user">
                              {/* <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div> */}
                              <div className="user-profile">
                                <img
                                  src={
                                    res?.profile_pic
                                      ? `${url}public/${res?.profile_pic}`
                                      : Logo
                                  }
                                  style={{ borderRadius: 50 }}
                                />
                                <div className="user-id">
                                  <p>{res?.name || "N/A"}</p>
                                  <span>{res?.email || "N/A"}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="user-role">
                              <p>
                                <span>
                                  <img
                                    src={
                                      res?.role === 1 ? UserIcon : TrainerIcon
                                    }
                                  />
                                </span>
                                {res?.role === 1 ? "User" : "Trainer"}
                              </p>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="cont-num">
                              {" "}
                              {res?.phone_no
                                ? res.country_code
                                  ? `${res.country_code} ${res.phone_no}`
                                  : `${res.phone_no}`
                                : "N/A"}{" "}
                            </div>
                          </td>
                          <td>
                            <div className="debit">{res?.age || "N/A"}</div>
                          </td>
                          <td>
                            <div
                              className={`${
                                res?.account_status === 1 ? "active" : "pending"
                              }`}
                            >
                              <p>
                                {res?.account_status === 1
                                  ? "Active"
                                  : "Inactive"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="action">
                              <img
                                src={
                                  require("../Assets/Images/delete-icon.svg")
                                    .default
                                }
                                onClick={() => {
                                  setshowd(true);
                                  setUserid(res?._id);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <Link
                                to={
                                  res?.role == 2
                                    ? `/trainer-details/${res?._id}`
                                    : `/user-details/${res?._id}`
                                }
                              >
                                <img
                                  src={
                                    require("../Assets/Images/view-icon.svg")
                                      .default
                                  }
                                />
                              </Link>

                              <OverlayTrigger
                                trigger="click"
                                show={activePopoverId === res._id}
                                placement="bottom"
                                overlay={popover(res?._id)}
                                onToggle={() => handleTogglePopover(res._id)}
                                rootClose
                              >
                                <img
                                  src={
                                    require("../Assets/Images/options.svg")
                                      .default
                                  }
                                  alt="Options"
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>

                              {/* <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            /> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="pagination-section">
                  <div className="showing-user">
                    <p>
                      {" "}
                      Showing {currentPage * limit} -{" "}
                      {Math.min((currentPage + 1) * limit, alldata?.count)} of{" "}
                      {alldata?.count} results
                    </p>
                  </div>
                  <div className="pagination-block">
                    <PaginationComponet
                      currentPage={currentPage}
                      totalCount={alldata?.count}
                      limit={Math.ceil(alldata?.count / limit)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="made-with">
            <div className="pixinvent">
              <p>
                {" "}
                © 2024, Made with ❤️ by <span>Codobux</span>
              </p>
            </div>
          </div>
        </Container>
      </>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
