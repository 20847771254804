import { configureStore } from "@reduxjs/toolkit";
import { usermanageSlice } from "../Redux/Reducer/UsermgmtSlice";
import { faqslice } from "../Redux/Reducer/FaqSlice";


export const Store = configureStore({
  reducer: {
    usermgt:usermanageSlice.reducer,
    faqmgmt:faqslice.reducer
  },
});